exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-bills-payment-tsx": () => import("./../../../src/pages/bills-payment.tsx" /* webpackChunkName: "component---src-pages-bills-payment-tsx" */),
  "component---src-pages-card-payment-tsx": () => import("./../../../src/pages/card-payment.tsx" /* webpackChunkName: "component---src-pages-card-payment-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-collections-tsx": () => import("./../../../src/pages/payment-collections.tsx" /* webpackChunkName: "component---src-pages-payment-collections-tsx" */),
  "component---src-pages-payouts-tsx": () => import("./../../../src/pages/payouts.tsx" /* webpackChunkName: "component---src-pages-payouts-tsx" */),
  "component---src-pages-privacy-and-terms-tsx": () => import("./../../../src/pages/privacy-and-terms.tsx" /* webpackChunkName: "component---src-pages-privacy-and-terms-tsx" */)
}

