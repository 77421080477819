// import React, { createContext, useContext, useState, useMemo } from "react";

// type ThemeContextProviderProps = {
//   children: any;
// };

// type Theme = boolean;

// // type ThemeContextType = {
// //   theme: Theme;
// //   setTheme: React.Dispatch<React.SetStateAction<Theme>>;
// // };

// export const ThemeContext = createContext<any>({});

// export const useThemeContext = () => useContext(ThemeContext);

// const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({
//   children,
// }) => {
//   const [theme, setTheme] = useState<Theme>(true);

//   const contextValues: any = useMemo(() => {
//     return {
//       theme,
//       setTheme,
//     };
//   }, [theme]);

//   return (
//     <ThemeContext.Provider value={contextValues}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };

// export default ThemeContextProvider;

import React, { createContext, useContext, useState, useMemo } from "react";

type ThemeContextProviderProps = {
  children: any;
};

type Theme = boolean;

export const ThemeContext = createContext<any>({});

export const useThemeContext = () => useContext(ThemeContext);

const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(false);

  const contextValues: any = useMemo(() => {
    return {
      theme,
      setTheme,
    };
  }, [theme]);

  return (
    <ThemeContext.Provider value={contextValues}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
