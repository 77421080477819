import React from "react";
import RootElement from "./src/components/RootElement";
import "./src/styles/global.css";
// import { load } from "rudder-sdk-js";

export const wrapRootElement = ({ element }) => {
  // load(
  //   "2YwAWbstkfNedXo4p2bVXfiSL5J",
  //   "https://usejujuolbyqo.dataplane.rudderstack.com/"
  // );
  return <RootElement>{element}</RootElement>;
};
