import React from "react";
import ThemeContextProvider from "../context/theme-context";
// import { load } from "rudder-sdk-js";

const RootElement = ({ children }: { children: JSX.Element }) => {
  // load(
  //   "2YwAWbstkfNedXo4p2bVXfiSL5J",
  //   "https://usejujuolbyqo.dataplane.rudderstack.com/"
  // );
  return <ThemeContextProvider>{children}</ThemeContextProvider>;
};

export default RootElement;
